<!-- <section>
    <div class="container-fluid">
        <div class="row">
            
            <div class="col-md-6 col-lg-6 col-sm-12 offset-md-3 offset-lg-3 vh-100 d-flex justify-content-center align-items-center">
                <div class="card">
                    <div class="card-body">
                        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                            <h2>Login</h2>
                            <div class="form-group">
                                <input type="email" class="form-control" id="exampleInputEmail1"
                                    formControlName="email" aria-describedby="emailHelp"
                                    placeholder="Enter email">
                                <span class="text-danger"
                                    *ngIf="(loginForm.controls['email'].touched || submitted) && loginForm.controls['email'].errors?.required">
                                    Email is required
                                </span>
                                <span class="text-danger"
                                    *ngIf="loginForm.controls['email'].touched && loginForm.controls['email'].errors?.email">
                                    Enter a valid email address
                                </span>
                            </div>
                            <div class="form-group">
                                <input type="password" class="form-control" formControlName="password"
                                    id="exampleInputPassword1" placeholder="Password">
                                <span class="text-danger"
                                    *ngIf="(loginForm.controls['password'].touched || submitted) && loginForm.controls['password'].errors?.required">
                                    Password is required
                                </span>
                            </div>
                            <button type="submit" class="btn">Login</button>
                        </form>
                    </div>
                </div>

            </div>
          
        </div>
    </div>
</section> -->
<section>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3 col-lg-3 col-sm-12"></div>
      <div
        class="col-md-6 col-lg-6 col-sm-12 vh-100 d-flex justify-content-center align-items-center"
        *ngIf="islogin == false"
      >
        <div class="card">
          <div class="card-body">
            <div class="row h-100">
              <div class="col-md-12 col-lg-12 col-sm-12 my-auto">
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                  <h2>Login</h2>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputEmail1"
                      formControlName="email"
                      aria-describedby="emailHelp"
                      placeholder="Enter email"
                    />
                    <span
                      class="text-danger"
                      *ngIf="
                        (loginForm.controls['email'].touched || submitted) &&
                        loginForm.controls['email'].errors?.required
                      "
                    >
                      Email is required
                    </span>
                    <span
                      class="text-danger"
                      *ngIf="
                        loginForm.controls['email'].touched &&
                        loginForm.controls['email'].errors?.email
                      "
                    >
                      Enter a valid email address
                    </span>
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      formControlName="password"
                      style="-webkit-text-security: circle"
                      id="exampleInputPassword1"
                      placeholder="Password"
                    />
                    <span
                      class="text-danger"
                      *ngIf="
                        (loginForm.controls['password'].touched || submitted) &&
                        loginForm.controls['password'].errors?.required
                      "
                    >
                      Password is required
                    </span>
                  </div>
                  <p (click)="forgot()" id="forgotpassword">Forgot Password?</p>
                  <button type="submit" class="btn mx-3">Login</button>
                  <button type="button" class="btn mx-3" (click)="onCancel()">
                    Cancel
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-md-6 col-lg-6 col-sm-12 vh-100 d-flex justify-content-center align-items-center"
        *ngIf="islogin == true"
      >
        <div class="card forgot">
          <div class="card-body">
            <form [formGroup]="loginForm" (ngSubmit)="onSubmitForgot()">
              <h2>Forgot Password</h2>
              <div class="form-group">
                <input
                  type="email"
                  class="form-control"
                  formControlName="forgot"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                />
                <span
                  class="text-danger"
                  *ngIf="
                    (loginForm.controls['forgot'].touched || submitted) &&
                    loginForm.controls['forgot'].errors?.required
                  "
                >
                  Email is required
                </span>
                <span
                  class="text-danger"
                  *ngIf="
                    loginForm.controls['forgot'].touched &&
                    loginForm.controls['forgot'].errors?.email
                  "
                >
                  Enter a valid email address
                </span>
              </div>
              <button type="submit" class="btn mx-3">Send</button>
              <button type="button" class="btn mx-3" (click)="onBack()">
                Back
              </button>
            </form>
          </div>
        </div>
      </div>
      <!-- <div class="col-md-3 col-lg-3 col-sm-12"></div> -->
    </div>
  </div>
</section>
