import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private refreshTokenRunning: boolean = false;
  private refreshSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(public authService: AuthService, private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = request;
    const token = this.authService.getToken();
    if (token !== null) {
      authReq = this.addTokenHeader(authReq, token); 
    }
    return next.handle(authReq).pipe(catchError((error: HttpErrorResponse) => {
      if (error.status === 401 && (!authReq.url.includes('/auth/admin/staff/login') || !authReq.url.includes('/auth/token/refresh'))) {
        return this.http401Error(authReq, next);
      } else {
        let errorMsg = error.status !== 0 ? error.status : 'Error';
        Swal.fire({
          title: errorMsg,
          icon: "error",
          text: `${error.message}`,
          showConfirmButton: true
        })
      }
      return throwError(error);
    }))
  }

  private http401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.refreshTokenRunning) {
      this.refreshTokenRunning = true;
      this.refreshSubject.next(null);
      const token = this.authService.getRefreshToken();
      if (token) {
        return this.authService.refreshToken(token).pipe(
          switchMap((token: any) => {
            this.refreshTokenRunning = false;
            this.authService.saveToken(token.access);
            this,this.refreshSubject.next(token.access);

            return next.handle(this.addTokenHeader(request, token.access));
          }),
          catchError((err) => {
            this.refreshTokenRunning = false;

            localStorage.clear();
            this.router.navigateByUrl('/login');
            return throwError(err);
          })
        );
      }
    }
    return this.refreshSubject.pipe(
      filter((token: any) => token !== null),
      take(1),
      switchMap((token: any) => next.handle(this.addTokenHeader(request, token)))
    )
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({headers: request.headers.set('Authorization', `Bearer ${token}`)});
  }

}
export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
]
