import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ConfirmPasswordValidator } from 'src/app/utils/confirm-password.validators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  verificationForm: FormGroup;
  id: string;
  token: string;
  token_valid: string;
  queryParamsStatus = '';
  forgotPassord: any;
  password: string;
  confirmPassword: string;
  submitted: boolean = false;
  resetToken: null;
  CurrentState: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authservice: AuthService
  ) {
    this.CurrentState = 'Wait';
    this.route.params.subscribe((params) => {
      this.resetToken = params.token;
      console.log(this.resetToken);
      // this.VerifyToken();
    });
  }

  ngOnInit(): void {
    this.verificationForm = this.formBuilder.group(
      {
        resettoken: [this.resetToken],

        password: [
          '',
          Validators.compose([Validators.required, Validators.minLength(8)]),
        ],
        confirmPassword: ['', Validators.compose([Validators.required])],
      },
      {
        validator: ConfirmPasswordValidator('password', 'confirmPassword'),
      }
    );

    // this.route.params.subscribe(event => {
    //   this.token = event.token;
    //   this.id = event.user_id;
    //  });
    //   this.route.queryParamMap.subscribe(queryParams => {
    //     if(queryParams.get("token_valid") == "True"){
    //       this.token = queryParams.get("token")!;
    //       this.id = queryParams.get("id")!;
    //       this.token_valid = queryParams.get("token_valid")!;
    //     }else{
    //       this.router.navigateByUrl('/login')
    //     }
    //  })

    //  console.log(this.id,this.token,this.token_valid)
    // let tempRows = this.rows.filter(s => s.staus === this.queryParamsStatus)
  }

  onSubmit() {
    this.forgotPassord = {
      password: this.verificationForm.value.password,
      hash: this.resetToken,
    };

    this.authservice.resetPass(this.forgotPassord).subscribe(
      (data) => {
        console.log(data);
        Swal.fire({
          title: 'Your password has been reset successfully ',
          icon: 'success',
          showConfirmButton: true,
        });

        // alert(data['message'])
        this.router.navigateByUrl('/login');
      },
      (err) => {
        console.log(err);
        Swal.fire({
          title: 'Error processing your request',
          icon: 'error',
          showConfirmButton: true,
        });
      }
    );
  }

  // onSubmit() {
  //   this.forgotPassord = {
  //     password: this.verificationForm.value.password,
  //     password2: this.verificationForm.value.confirmPassword,
  //     utoken: this.token,
  //     uid: this.id,
  //   };
  //   this.authservice.resetPass(this.forgotPassord).subscribe(
  //     (data) => {
  //       console.log(data);
  //       Swal.fire({
  //         title: data['message'],
  //         icon: 'success',
  //         showConfirmButton: true,
  //       });

  //       // alert(data['message'])
  //       this.router.navigateByUrl('/login');
  //     },
  //     (err) => {
  //       console.log(err);
  //       alert(err);
  //     }
  //   );
  // }
}
