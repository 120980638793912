import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Staff } from '../model/staff';
import { Profile } from '../model/profile';

interface User {
  id: string;
  email: string;
  password: string;
  redirect_url: string;
}
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  BASE_URL: string = 'http://64.227.146.27:3000/api/v1/';
  // BASE_URL: string = 'http://localhost:3000/api/v1/';

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
  };
  _userRole: string;
  isLogin: boolean = false;

  constructor(private http: HttpClient) {}

  gettoken() {
    return !!localStorage.getItem('access_token');
  }

  getToken() {
    let token = localStorage.getItem('access_token');
    if (token) {
      return token;
    } else {
      return null;
    }
  }

  saveToken(token: string) {
    localStorage.setItem('access_token', token);
  }

  getRefreshToken() {
    let refreshToken = localStorage.getItem('refresh_token');
    if (refreshToken) {
      return refreshToken;
    } else {
      return null;
    }
  }

  getUserRole(): string {
    let role = localStorage.getItem('role');
    console.log(typeof role);
    if (role === 'true') {
      this._userRole = 'Superuser';
    } else {
      this._userRole = 'Admin';
    }
    console.log(this._userRole);
    return this._userRole;
  }

  isLoggedIn() {
    const token = localStorage.getItem('access_token');
    if (token) {
      this.isLogin = true;
    } else {
      this.isLogin = false;
    }
    return this.isLogin;
  }

  login(email: String, password: string): Observable<any> {
    let user = {
      email: email,
      password: password,
    };
    console.log(user);
    return this.http.post<any>(
      this.BASE_URL + 'auth/admin/staff/login/',
      JSON.stringify(user),
      this.httpOptions
    );
  }

  refreshToken(token: string) {
    let refreshObj = {
      refresh: token,
    };
    let httpHeader = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post(
      this.BASE_URL + 'auth/token/refresh/',
      JSON.stringify(refreshObj),
      httpHeader
    );
  }

  resetPass(forgotPass: any): Observable<any> {
    return this.http.post<any>(
      this.BASE_URL + 'auth/reset/password/',
      JSON.stringify(forgotPass),
      this.httpOptions
    );
  }

  // forgotPass(user:any) :  Observable<any> {
  //   return this.http.post<any>(this.BASE_URL + 'auth/request-reset-password-email/', JSON.stringify(user), this.httpOptions);
  // }

  forgotPass(User: any): Observable<User> {
    console.log(JSON.stringify(User));
    return this.http.post<User>(
      'http://64.227.146.27:3000/api/v1/auth/admin/forgot/password',
      JSON.stringify(User),
      this.httpOptions
    );
  }

  getfile(filename: string): Observable<any> {
    let token = localStorage.getItem('access_token');
    let httpHeader = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.http.get<any>(this.BASE_URL + `files/${filename}`, httpHeader);
  }

  registerStaff(data: Staff): Observable<Staff> {
    let token = localStorage.getItem('access_token');
    let httpHeader = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.http.post<Staff>(
      this.BASE_URL + 'auth/admin/staff/register/',
      JSON.stringify(data),
      httpHeader
    );
  }

  getStaffDetail(id: string): Observable<Staff> {
    let token = localStorage.getItem('access_token');
    let httpHeader = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.http.get<Staff>(
      this.BASE_URL + `admin/staff/detail/${id}/`,
      httpHeader
    );
  }

  updateStaffDetail(data: any, adminId: string): Observable<Staff> {
    let token = localStorage.getItem('access_token');
    let httpHeader = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.http.patch<any>(
      this.BASE_URL + `admin/staff/detail/${adminId}/`,
      JSON.stringify(data),
      httpHeader
    );
  }

  fetchStaff(): Observable<Staff[]> {
    let token = localStorage.getItem('access_token');
    let httpHeader = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.http
      .get<Staff[]>(this.BASE_URL + 'admin/staff/', httpHeader)
      .pipe(
        map((val) => {
          console.log('Service: ', val);
          return val.filter((item) => item.is_superuser !== true);
        })
      );
  }

  fetchGroupAdminStaff(): Observable<Staff[]> {
    let token = localStorage.getItem('access_token');
    let httpHeader = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.http.get<Staff[]>(
      this.BASE_URL + 'admin/staff/group-admin/',
      httpHeader
    );
  }

  fetchSeniorAdminStaff(): Observable<Staff[]> {
    let token = localStorage.getItem('access_token');
    let httpHeader = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.http.get<Staff[]>(
      this.BASE_URL + 'admin/staff/senior-admin/',
      httpHeader
    );
  }

  fetchDoctorAdminStaff(): Observable<Staff[]> {
    let token = localStorage.getItem('access_token');
    let httpHeader = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.http.get<Staff[]>(
      this.BASE_URL + 'admin/staff/doctor-admin/',
      httpHeader
    );
  }

  fetchStaffCategoryWise(category: string): Observable<Staff[]> {
    let token = localStorage.getItem('access_token');
    let httpHeader = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.http.get<Staff[]>(
      this.BASE_URL + `admin/staff/${category}/`,
      httpHeader
    );
  }

  fetchAllUsers(): Observable<any[]> {
    let token = localStorage.getItem('access_token');
    let httpHeader = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.http
      .get<any[]>(this.BASE_URL + 'admin/user/all_user/', httpHeader)
      .pipe(
        map((val) => {
          console.log(val);
          let res = val.filter((item: any) => item.user_type == 6);
          console.log(res);
          return res;
          //  return val;
        })
        // switchMap((val) =>  {
        //   console.log(val);
        //   let res = val.map((item) => {
        //    return this.http.get<any[]>(this.BASE_URL + `admin/user/profile/${item.id}/`, httpHeader);
        //   });
        //   console.log("Res: ",res)
        //   return res;
        // })
      );
  }

  fetchUserProfile(id: string): Observable<Profile> {
    let token = localStorage.getItem('access_token');
    let httpHeader = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.http.get<Profile>(
      this.BASE_URL + `user/profile/${id}/`,
      httpHeader
    );
  }

  fetchHealthReport(id: string): Observable<any[]> {
    let token = localStorage.getItem('access_token');
    let httpHeader = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.http.get<any[]>(
      this.BASE_URL + `admin/user/healthreport/${id}/`,
      httpHeader
    );
  }

  fetchBmiReport(id: string): Observable<any[]> {
    let token = localStorage.getItem('access_token');
    let httpHeader = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.http.get<any[]>(
      this.BASE_URL + `admin/user/monthlyreports/${id}/`,
      httpHeader
    );
  }

  fetchAdminProfile(): Observable<Profile[]> {
    let token = localStorage.getItem('access_token');
    let httpHeader = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.http.get<Profile[]>(
      this.BASE_URL + `admin/staff/profile/`,
      httpHeader
    );
  }

  addProfile(profile: Profile): Observable<Profile> {
    let token = localStorage.getItem('access_token');
    let httpHeader = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.http.post<Profile>(
      this.BASE_URL + 'admin/staff/profile/',
      JSON.stringify(profile),
      httpHeader
    );
  }

  updateProfile(profile: Profile, profileId: string): Observable<Profile> {
    let token = localStorage.getItem('access_token');
    let httpHeader = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.http.put<Profile>(
      this.BASE_URL + `admin/staff/profile/${profileId}/`,
      JSON.stringify(profile),
      httpHeader
    );
  }

  fetchSingleAdminProfile(id: string): Observable<Profile> {
    let token = localStorage.getItem('access_token');
    let httpHeader = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.http.get<Profile>(
      this.BASE_URL + `admin/su/staff/profile/${id}/`,
      httpHeader
    );
  }
}
