<section>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-8 col-lg-8 col-sm-12 offset-md-2 offset-lg-2">

                <form [formGroup]="updatePersonalDetails" (ngSubmit)="updateProfile()">
                    <h2>Personal Details Form</h2>
                    <div class="row">
                        <div class="form-group col-md-6 col-lg-6 col-sm-12">
                            <input class="form-control" placeholder="First Name" type="text" id="firstName"
                                formControlName="firstName" [(ngModel)]="firstName" />
                            <span class="text-danger"
                                *ngIf="(updatePersonalDetails.controls['firstName'].touched || submitted) && updatePersonalDetails.controls['firstName'].errors?.required">
                                First Name is required
                            </span>
                        </div>

                        <div class="form-group col-md-6 col-lg-6 col-sm-12">
                            <input class="form-control" placeholder="Last Name" type="text" id="lastName"
                                formControlName="lastName" [(ngModel)]="lastName" />
                            <span class="text-danger"
                                *ngIf="(updatePersonalDetails.controls['lastName'].touched || submitted) && updatePersonalDetails.controls['lastName'].errors?.required">
                                Last Name is required
                            </span>
                        </div>

                        <div class="form-group col-md-12 col-lg-12 col-sm-12">
                            <div class="row">
                                <div class="col-md-3 col-lg-3 col-sm-12" style="text-align: left; font-weight: bold;">
                                    <label for="gender">Gender :</label>
                                </div>
                                <div class="col-md-3 col-lg-3 col-sm-12">
                                    <input type="radio" name="gender" id="male" formControlName="gender" value="m"
                                        [(ngModel)]="gender" />
                                    <label for="male">Male</label>
                                </div>
                                <div class="col-md-3 col-lg-3 col-sm-12">
                                    <input type="radio" name="gender" id="female" formControlName="gender"
                                        value="f" [(ngModel)]="gender" />
                                    <label for="female">Female</label>
                                </div>
                                <div class="col-md-3 col-lg-3 col-sm-12">
                                    <input type="radio" name="gender" id="other" formControlName="gender" value="o"
                                        [(ngModel)]="gender" />
                                    <label for="other">Other</label>
                                </div>
                                <span class="text-danger"
                                    *ngIf="(updatePersonalDetails.controls['gender'].touched || submitted) && updatePersonalDetails.controls['gender'].errors?.required">
                                    Kindly select gender.
                                </span>
                            </div>
                        </div>

                        <div class="form-group col-md-2 col-lg-2 col-sm-12">
                            <h6>DOB :</h6>
                        </div>

                        <div class="form-group col-md-5 col-lg-5 col-sm-12">
                            <input class="form-control" placeholder="Date of birth" type="date" id="dob"
                                [max]="eighteenYearsAgo | date: 'dd/MM/yyyy'" formControlName="dob" [(ngModel)]="dob"
                                (change)="onChange()" />
                            <span class="text-danger"
                                *ngIf="(updatePersonalDetails.controls['dob'].touched || submitted) && updatePersonalDetails.controls['dob'].errors?.required">
                                Date of birth is required
                            </span>
                        </div>

                        <div class="form-group col-md-5 col-lg-5 col-sm-12">
                            <input class="form-control" placeholder="Age" type="text" id="age" formControlName="age"
                                [(ngModel)]="age" readonly />
                            <span class="text-danger"
                                *ngIf="(updatePersonalDetails.controls['age'].touched || submitted) && updatePersonalDetails.controls['age'].errors?.min">
                                To signup your age should be 18+.
                            </span>
                        </div>

                        <div class="form-group col-md-3 col-lg-3 col-sm-12" style="text-align: left; font-weight: bold;">
                            <label for="country">Select Country : </label>
                        </div>

                        <div class="form-group col-md-9 col-lg-9 col-sm-12">
                            <select class="form-select" id="country" formControlName="country" [(ngModel)]="country"
                                (change)="onCountrySelect()">
                                <option *ngFor="let country of countries" [value]="country.iso2">{{
                                    country.name }}
                                </option>
                            </select>
                            <span class="text-danger"
                                *ngIf="(updatePersonalDetails.controls['country'].touched || submitted) && updatePersonalDetails.controls['country'].errors?.required">
                                Country must be selected
                            </span>
                        </div>

                        <div class="form-group col-md-3 col-lg-3 col-sm-12" style="text-align: left; font-weight: bold;">
                            <label for="state">Select State : </label>
                        </div>

                        <div class="form-group col-md-9 col-lg-9 col-sm-12">
                            <select class="form-select" id="state" formControlName="state" [(ngModel)]="state"
                                (change)="onStateSelect()">
                                <option *ngFor="let state of states" [value]="state.iso2">{{ state.name }}
                                </option>
                            </select>
                            
                            <span class="text-danger"
                                *ngIf="(updatePersonalDetails.controls['state'].touched || submitted) && updatePersonalDetails.controls['state'].errors?.required">
                                State must be selected
                            </span>
                        </div>

                        <div class="form-group col-md-3 col-lg-3 col-sm-12"  *ngIf="!isOthersSelected" style="text-align: left; font-weight: bold;">
                            <label for="city">Select City : </label>
                        </div>

                        <div class="form-group col-md-9 col-lg-9 col-sm-12" *ngIf="!isOthersSelected">
                            <select class="form-select" id="city" formControlName="city" [(ngModel)]="city">
                                <option *ngFor="let city of cities" [value]="city.name">{{ city.name }}
                                </option>
                            </select>
                            <span class="text-danger"
                                *ngIf="(updatePersonalDetails.controls['city'].touched || submitted) && updatePersonalDetails.controls['city'].errors?.required">
                                City must be selected
                            </span>
                        </div>

                        <div class="form-check col-md-12 col-lg-12 col-sm-12">
                            <p>**Incase you dont find your city, click on others and type in your city name.</p>
                        </div>

                        <div class="form-check col-md-1 col-lg-1 col-sm-2">
                            <input class="form-check-input" type="checkbox" [value]="isOthersSelected"
                                id="otherCityCheck" [checked]="isOthersSelected" (change)="enterOtherCity()" />
                        </div>

                        <div class="col-md-11 col-lg-11 col-sm-10" style="text-align: left;">
                            <label class="form-check-label" for="otherCityCheck">Other City/Village</label>

                        </div>

                        <div class="form-group col-md-12 col-lg-12 col-sm-12" *ngIf="isOthersSelected">
                            <input class="form-control" type="text" placeholder="City/Village" id="cityText"
                                formControlName="city" [(ngModel)]="city" />
                            <span class="text-danger"
                                *ngIf="(updatePersonalDetails.controls['city'].touched || submitted) && updatePersonalDetails.controls['city'].errors?.required">
                                City is required.
                            </span>
                        </div>


                        <div class="col-md-6 col-lg-6 col-sm-12 button">
                            <button class="btn btn-primary  btn-md btn-block" (click)="onCancel()">Back</button>
                        </div>
                        <div class="col-md-6 col-lg-6 col-sm-12 button">
                            <button type="submit" class="btn btn-primary  btn-md btn-block">Update</button>
                        </div>
                    </div>


                </form>
            </div>
        </div>
    </div>


</section>
