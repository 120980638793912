import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';
import { CountryService } from 'src/app/services/country.service';
import { Profile } from 'src/app/model/profile';

@Component({
  selector: 'app-create-profile',
  templateUrl: './create-profile.component.html',
  styleUrls: ['./create-profile.component.scss']
})
export class CreateProfileComponent implements OnInit {
  personalDetailsForm: FormGroup;

  profile: Profile;

  firstName: string;
  lastName: string;
  gender: string;
  dob: Date;
  age: number;
  city: any;
  state: any;
  country: any;

  eighteenYearsAgo = new Date();

  tempObj: any;

  countries: any[];
  states: any[];
  cities: any[];

  isOthersSelected: boolean = false;

  submitted: boolean = false;

  constructor(private router: Router, private formBuilder: FormBuilder, private countryService: CountryService, private authService: AuthService, private location: Location) {
    this.personalDetailsForm = this.formBuilder.group({
      firstName: ['', Validators.compose([Validators.required])],
      lastName: ['', Validators.compose([Validators.required])],
      gender: ['', Validators.compose([Validators.required])],
      dob: ['', Validators.compose([Validators.required])],
      age: ['', Validators.compose([Validators.min(18)])],
      country: ['', Validators.compose([Validators.required])],
      state: ['', Validators.compose([Validators.required])],
      city: ['', Validators.compose([Validators.required])],
      // cityText: ['']
    });

  }
  ngOnInit(): void {
    this.getAllCountries();
  }

  fetchPastDate() {
    this.eighteenYearsAgo = new Date(this.eighteenYearsAgo.setFullYear(this.eighteenYearsAgo.getFullYear() - 18));
    console.log(this.eighteenYearsAgo);
  }

  getAllCountries() {
    this.countryService.fetchAllCountries().subscribe({
      next: (data) => {
        this.countries = data;
        console.log(this.countries);
      },
      error: (err) => console.error('Fetching Countries Error: ', err)
    })
  }

  getMonthDiffrence(startDate: Date, endDate: Date) {
    return (
      endDate.getMonth() - startDate.getMonth() + 12 * (endDate.getFullYear() - startDate.getFullYear())
    );
  }

  onChange() {
    let dob = new Date(this.dob);
    let todayDate = new Date();
    let monthsDifference = this.getMonthDiffrence(dob, todayDate);
    let age = 0;
    console.log(monthsDifference / 12);

    age = Math.round(monthsDifference / 12);

    this.age = age;
  }

  onCountrySelect() {
    if (!this.country) return;
    console.log(this.country);
    
    this.countryService.fetchStateByCountry(this.country).subscribe({
      next: (data) => {
        this.states = data;
        console.log('States: ', this.states);
      },
      error: (err) => console.error('Fetching States Error: ', err)
    })
  }

  onStateSelect() {
    if (!this.state) return;
    console.log(this.state);
    this.countryService.fetchCitiesByState(this.country, this.state).subscribe({
      next: (data) => {
        this.cities = data;
        console.log('Cities: ', this.cities);
      },
      error: (err) => console.error('Fetching Cities Error: ', err)
    })
  }

  enterOtherCity() {
    this.isOthersSelected = !this.isOthersSelected;
    localStorage.setItem('othersSelected', JSON.stringify(this.isOthersSelected));
    console.log(this.isOthersSelected);
  }

  addProfile() {
    if (!this.personalDetailsForm.valid) {
      console.error("Form is invalid.");
    } else {
      this.profile = {
        first_name: this.personalDetailsForm.value.firstName,
        last_name: this.personalDetailsForm.value.lastName,
        date_of_birth: this.personalDetailsForm.value.dob,
        age: this.personalDetailsForm.value.age,
        gender: this.personalDetailsForm.value.gender,
        country: this.personalDetailsForm.value.country,
        state: this.personalDetailsForm.value.state,
        city: this.personalDetailsForm.value.city
      }
      console.log(this.profile);
      this.authService.addProfile(this.profile).subscribe({
        next: (data) => {
          console.log(data);
          this.personalDetailsForm.reset();
          this.location.back();
        },
        error: (err) => console.error(err)
      })

    }
  }

  onCancel() {
    this.personalDetailsForm.reset();
    this.location.back();
  }

}
