import { AbstractControl, FormGroup } from "@angular/forms";

export function ConfirmPasswordValidator(controlName: string, matchControlName: string) {
    return (formGroup: FormGroup) => {
        let control = formGroup.controls[controlName];
        let matchControl = formGroup.controls[matchControlName];

        if (matchControl.errors && !matchControl.errors.confirmPasswordValidator) return;

        if (control.value !== matchControl.value) {
            matchControl.setErrors({ confirmPasswordValidator: true })
        } else {
            matchControl.setErrors(null);
        }
    }
}