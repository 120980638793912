import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let url: string = state.url;
    return this.checkUserLogin(route, url);
  }

  checkUserLogin(route: ActivatedRouteSnapshot, url: any): boolean {

    console.log('Login: ', this.authService.isLoggedIn());
    if (this.authService.isLoggedIn()) {
      const userRole = this.authService.getUserRole();
      console.log('User Role: ', userRole);
      if (route.data.role && route.data.role.indexOf(userRole) === -1) {
        this.router.navigateByUrl('/login');
        localStorage.clear();
        return false;
      }
      return true;
    }
    this.router.navigateByUrl('/login');
    localStorage.clear();
    return false;
  }

}
