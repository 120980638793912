import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ObservableLike } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  API_KEY = "dG92MEszdGNROHNmQ2dEUHJ3bk1GaHJPN3Z4NE82QlI0dEsxVjYzaQ=="
  BASE_URL = "https://api.countrystatecity.in/v1/countries"

  constructor(private http: HttpClient) { }

  fetchAllCountries(): Observable<any[]> {
    let httpHeader = {
      headers: new HttpHeaders({
        'X-CSCAPI-KEY': this.API_KEY
      })
    }
    return this.http.get<any[]>(this.BASE_URL, httpHeader);
  }

  fetchStateByCountry(iso: string): Observable<any[]> {
    let httpHeader = {
      headers: new HttpHeaders({
        'X-CSCAPI-KEY': this.API_KEY
      })
    }
    return this.http.get<any[]>(this.BASE_URL+`/${iso}/states`, httpHeader);
  }

  fetchCitiesByState(countryIso: string, stateIso: string): Observable<any[]> {
    let httpHeader = {
      headers: new HttpHeaders({
        'X-CSCAPI-KEY': this.API_KEY
      })
    }
    return this.http.get<any[]>(this.BASE_URL+`/${countryIso}/states/${stateIso}/cities`, httpHeader);
  }

  fetchCountryDetails(countryIso: string): Observable<any> {
    let httpHeader = {
      headers: new HttpHeaders({
        'X-CSCAPI-KEY': this.API_KEY
      })
    }
    return this.http.get<any>(this.BASE_URL + `/${countryIso}`, httpHeader);
  }

  fetchStateDetails(countryIso: string, stateIso: string): Observable<any> {
    let httpHeader = {
      headers: new HttpHeaders({
        'X-CSCAPI-KEY': this.API_KEY
      })
    }
    return this.http.get<any>(this.BASE_URL + `/${countryIso}/states/${stateIso}`, httpHeader);
  }
}
