<section>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6 col-lg-6 col-sm-12 offset-md-3 offset-lg-3 vh-100 d-flex justify-content-center align-items-center">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12 col-lg-12 col-sm-12">
                                <form [formGroup]="verificationForm" (ngSubmit)="onSubmit()">
                                  
                                    <div class="row">
                                        <h2>Reset Password</h2>
                                        <div class="form-group col-md-12 col-lg-12 col-sm-12">
                                            <input class="form-control" placeholder="Password*" type="password" id="password"
                                                formControlName="password" [(ngModel)]="password" />
                                            <span class="text-danger"
                                                *ngIf="(verificationForm.controls['password'].touched || submitted) && verificationForm.controls['password'].errors?.required">
                                                Password is required.
                                            </span>
                                            <span class="text-danger" *ngIf="verificationForm.get('password')?.hasError('minlength')">
                                                Password must be 8 characters long.
                                            </span>
                                        </div>
                
                                        <div class="form-group col-md-12 col-lg-12 col-sm-12">
                                            <input class="form-control" placeholder="Confirm Password*" type="text" id="confirmPassword"
                                                formControlName="confirmPassword" [(ngModel)]="confirmPassword" />
                                            <span class="text-danger"
                                                *ngIf="(verificationForm.controls['confirmPassword'].touched || submitted) && verificationForm.controls['confirmPassword'].errors?.required">
                                                Confirm Password is required.
                                            </span>
                                            <span class="text-danger"
                                                *ngIf="verificationForm.get('confirmPassword')?.hasError('confirmPasswordValidator')">
                                                Confirm Password does not match.
                                            </span>
                                        </div>
                
                                        <div class="form-group col-md-12 col-lg-12 col-sm-12">
                                                <button class="btn" type="submit">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
