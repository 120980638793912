import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from "@angular/common";
import { Router } from '@angular/router';
import { Profile } from 'src/app/model/profile';
import { AuthService } from 'src/app/services/auth.service';
import { CountryService } from 'src/app/services/country.service';

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.scss']
})
export class UpdateProfileComponent implements OnInit {

  updatePersonalDetails: FormGroup;

  profile: Profile;

  profileId: string;
  firstName: string;
  lastName: string;
  gender: string;
  dob: Date;
  age: number;
  city: any;
  state: any;
  country: any;

  eighteenYearsAgo = new Date();

  tempObj: any;

  countries: any[];
  states: any[];
  cities: any[];

  isOthersSelected: boolean = false;

  submitted: boolean = false;

  constructor(private router: Router, private formBuilder: FormBuilder, private countryService: CountryService, private authService: AuthService, private location: Location) {
    if (this.router.getCurrentNavigation()?.extras.state) {
      this.profile = this.router.getCurrentNavigation()?.extras.state?.profileData;
      this.profileId = this.profile.id!;
      console.log(this.profileId);
    }


    this.updatePersonalDetails = this.formBuilder.group({
      firstName: ['', Validators.compose([Validators.required])],
      lastName: ['', Validators.compose([Validators.required])],
      gender: ['', Validators.compose([Validators.required])],
      dob: ['', Validators.compose([Validators.required])],
      age: ['', Validators.compose([Validators.min(18)])],
      country: ['', Validators.compose([Validators.required])],
      state: ['', Validators.compose([Validators.required])],
      city: ['', Validators.compose([Validators.required])],
      // cityText: ['']
    });
  }

  ngOnInit(): void {
    this.getAllCountries();
  }

  fetchPastDate() {
    this.eighteenYearsAgo = new Date(this.eighteenYearsAgo.setFullYear(this.eighteenYearsAgo.getFullYear() - 18));
    console.log(this.eighteenYearsAgo);
  }

  getAllCountries() {
    this.countryService.fetchAllCountries().subscribe({
      next: (data) => {
        this.countries = data;
        console.log(this.countries);
        if (this.profile !== null || this.profile !== undefined) {
          this.firstName = this.profile.first_name;
          this.lastName = this.profile.last_name;
          this.dob = this.profile.date_of_birth;
          this.age = this.profile.age;
          this.gender = this.profile.gender;
          this.country = this.profile.country;
          this.state = this.profile.state;
          this.city = this.profile.city;
        }
        this.fetchStates(this.country);
        this.fetchCities(this.country, this.state);
      },
      error: (err) => console.error('Fetching Countries Error: ', err)
    })
  }

  getMonthDiffrence(startDate: Date, endDate: Date) {
    return (
      endDate.getMonth() - startDate.getMonth() + 12 * (endDate.getFullYear() - startDate.getFullYear())
    );
  }

  onChange() {
    let dob = new Date(this.dob);
    let todayDate = new Date();
    let monthsDifference = this.getMonthDiffrence(dob, todayDate);
    let age = 0;
    console.log(monthsDifference / 12);

    age = Math.round(monthsDifference / 12);

    this.age = age;
  }

  fetchStates(country: string) {
    this.countryService.fetchStateByCountry(country).subscribe({
      next: (data) => {
        this.states = data;
        console.log('States: ', this.states);
      },
      error: (err) => console.error('Fetching States Error: ', err)
    })
  }

  onCountrySelect() {
    if (!this.country) return;
    console.log(this.country);

    this.fetchStates(this.country);
  }

  fetchCities(country: string, state: string) {
    this.countryService.fetchCitiesByState(country, state).subscribe({
      next: (data) => {
        this.cities = data;
        console.log('Cities: ', this.cities);
      },
      error: (err) => console.error('Fetching Cities Error: ', err)
    })
  }

  onStateSelect() {
    if (!this.state && !this.country) return;
    console.log(this.state);

    this.fetchCities(this.country, this.state);
  }

  enterOtherCity() {
    this.isOthersSelected = !this.isOthersSelected;
    localStorage.setItem('othersSelected', JSON.stringify(this.isOthersSelected));
    console.log(this.isOthersSelected);
  }

  onCancel() {
    this.location.back();
  }

  updateProfile() {
    if (!this.updatePersonalDetails.valid) {
      console.log('Form is invalid.')
    } else {
      console.log(this.updatePersonalDetails.value);
      this.profile = {
        first_name: this.updatePersonalDetails.value.firstName,
        last_name: this.updatePersonalDetails.value.lastName,
        date_of_birth: this.updatePersonalDetails.value.dob,
        age: this.updatePersonalDetails.value.age,
        gender: this.updatePersonalDetails.value.gender,
        country: this.updatePersonalDetails.value.country,
        state: this.updatePersonalDetails.value.state,
        city: this.updatePersonalDetails.value.city
      }
      console.log(this.profile);
      this.authService.updateProfile(this.profile, this.profileId).subscribe({
        next: (data) => {
          console.log(data);
          this.updatePersonalDetails.reset();
          this.location.back();
        },
        error: (err) => console.error(err)
      })

    }
  }

}
