import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  islogin: boolean = false;
  inputPassword: boolean;
  inputText: boolean;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      forgot: [''],
    });
  }

  login(email: string, password: string) {
    this.authService.login(email, password).subscribe(
      (data) => {
        // alert(data['msg']);
        console.log(data['token']['access']);
        let accessToken = data['token'];
        localStorage.setItem('access_token', accessToken);
        let refreshToken = data['refreshToken'];
        localStorage.setItem('refresh_token', accessToken);
        let isSuperuser = data['user']['is_superuser'];
        console.log('Is Superuser', isSuperuser);
        localStorage.setItem('role', isSuperuser);
        // this.authService.setUserRole = isSuperuser;
        console.log('Is Superuser', isSuperuser);
        if (isSuperuser) {
          console.log('working');
          this.router.navigateByUrl('/superAdmin/dashboard');
        } else {
          localStorage.setItem('id', data['user']['id']);
          this.router.navigateByUrl('/admin/dashboard', {
            state: { adminId: data['user']['id'] },
          });
        }
      },
      (err) => {
        if (err.status == 422) {
          Swal.fire({
            title: err['error']['errors'].email,
            // text: 'Please verify your mail',
            icon: 'error',
            showConfirmButton: true,
          });
        }
      }
    );
  }

  focusFunction(type: string) {
    if (type == 'text') {
      this.inputText = false;
      this.inputPassword = true;
    } else if (type == 'pw') {
      this.inputText = true;
      this.inputPassword = false;
    }
  }

  onCancel() {
    this.loginForm.reset();
  }

  async onSubmit() {
    this.submitted = true;
    if (!this.loginForm.valid) {
      alert('Invalid user and password');
    } else {
      let email = this.loginForm.value.email;
      let password = this.loginForm.value.password;
      await this.login(email, password);
    }
  }

  forgot() {
    this.islogin = true;
    this.loginForm.get('email')?.clearValidators();
    this.loginForm.get('email')?.updateValueAndValidity();
    this.loginForm.get('password')?.clearValidators();
    this.loginForm.get('password')?.updateValueAndValidity();
    this.loginForm
      .get('forgot')
      ?.setValidators([Validators.required, Validators.email]);
    this.loginForm.get('forgot')?.updateValueAndValidity();
  }

  onBack() {
    this.islogin = false;
    this.loginForm
      .get('email')
      ?.setValidators([Validators.required, Validators.email]);
    this.loginForm.get('email')?.updateValueAndValidity();
    this.loginForm.get('password')?.setValidators([Validators.required]);
    this.loginForm.get('password')?.updateValueAndValidity();
    this.loginForm.get('forgot')?.clearValidators();
    this.loginForm.get('forgot')?.updateValueAndValidity();
  }

  // onSubmitForgot() {
  //   let verification = {
  //     "email": this.loginForm.value.forgot,
  //     "redirect_url": "http://localhost:4200/forgot-password"
  //   }
  //   this.authService.forgotPass(verification).subscribe(data => {
  //     console.log(data);
  //   //  alert(data['success'])
  //   },
  //   err =>{
  //     console.log(err)
  //     alert(err)
  //   })
  // }

  onSubmitForgot() {
    let verification = {
      email: this.loginForm.value.forgot,
      redirect_url: 'http://localhost:4200/forgot-password',
    };
    this.authService.forgotPass(verification).subscribe(
      (data) => {
        console.log(data);
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'We have sent you an email. Please check to reset your password.',
          footer: '<a href="/login">Proceed to Login</a>',
        });
      },
      (error) => {
        console.log(error);
        Swal.fire({
          title: 'Error processing your request',
          // text: 'Please verify your mail',
          icon: 'error',
          showConfirmButton: true,
        });
      }
    );
  }
}
